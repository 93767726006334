import Button from "@/components/button";
import loader from "@/components/loader";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

export default {
    name: "modal",
    components: {
        "neo-button": Button,
        "neo-loader": loader,
        "font-awesome-icon": FontAwesomeIcon,
    },
    props: ["title", "description", "submitButtonText", "submitButtonColor", "submitButtonHoverColor", "loading", "success", "disabled"],
    data() {
        return {};
    },
    computed: {},
    mounted() {},
    methods: {},
};
